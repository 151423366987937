import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8a1b17a8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=8a1b17a8&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a1b17a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoverSection: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/cover-section/cover-section.vue').default,HighlightsSection: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/HighlightsSection/HighlightsSection.vue').default,BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default,BaseContainer: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseContainer.vue').default})
