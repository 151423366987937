import { render, staticRenderFns } from "./HighlightsSection.vue?vue&type=template&id=4cb4496c&scoped=true&"
import script from "./HighlightsSection.vue?vue&type=script&lang=ts&"
export * from "./HighlightsSection.vue?vue&type=script&lang=ts&"
import style0 from "./HighlightsSection.vue?vue&type=style&index=0&id=4cb4496c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb4496c",
  null
  
)

/* custom blocks */
import block0 from "./HighlightsSection.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseText.vue').default,BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default,HighlightItem: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/HighlightsSection/HighlightItem/HighlightItem.vue').default,BaseContainer: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseContainer.vue').default})
