










import Vue from 'vue'
import { PropOptions } from 'vue/types'
import { Highlight } from '~/types/highlight'

export default Vue.extend({
  name: 'HighlightItem',

  props: {
    highlight: {
      type: Object,
      required: true,
    } as PropOptions<Highlight>,
  },
  computed: {
    imageSource(): string {
      return require(`@ui/assets/icons/${this.highlight.image}`)
    },
  },
})
