


















import Vue from 'vue'
import { Highlight } from '~/types/highlight'

export default Vue.extend({
  name: 'HighlightsSection',

  computed: {
    highlights(): Highlight[] {
      return this.$t('highlights') as unknown as Highlight[]
    },
  },
})
