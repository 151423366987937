




























import Vue from 'vue'

export default Vue.extend({
  computed: {
    listTexts(): string[] {
      return this.$t('list') as unknown as string[]
    },
  },
})
