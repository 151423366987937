import { render, staticRenderFns } from "./cover-section.vue?vue&type=template&id=0d100566&scoped=true&"
import script from "./cover-section.vue?vue&type=script&lang=ts&"
export * from "./cover-section.vue?vue&type=script&lang=ts&"
import style0 from "./cover-section.vue?vue&type=style&index=0&id=0d100566&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d100566",
  null
  
)

/* custom blocks */
import block0 from "./cover-section.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseText.vue').default,BaseContainer: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseContainer.vue').default})
