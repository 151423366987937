// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons/Checkmark.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-0d100566]{--space:30px}.cover-section[data-v-0d100566]{background:linear-gradient(90deg,var(--gradient-multidomain));margin-bottom:calc(var(--space)*3)}.cover-section__grid[data-v-0d100566]{display:grid;gap:0;padding:0;grid-template-columns:1fr;grid-template-areas:\"heading\" \"image\" \"list\" \"acions\";}.cover-section__grid >*[data-v-0d100566]{padding:0 var(--space)}@media (min-width: 900px){.cover-section__grid[data-v-0d100566]{padding:0 0 var(--space) var(--space);grid-template-columns:1fr 1fr 45%;grid-template-areas:\"heading heading image\" \"list list image\"}}.cover-section__heading[data-v-0d100566]{grid-area:heading;line-height:1.1;color:var(--color-white-base);margin-top:0;padding:var(--space);font-size:2.5rem;}@media (min-width: 900px){.cover-section__heading[data-v-0d100566]{padding:var(--space) 0;margin-top:3rem;font-size:3.5rem}}.cover-section__list[data-v-0d100566]{grid-area:list;font-size:1.375rem;}@media (max-width: 900px){.cover-section__list[data-v-0d100566]{border-bottom-color:rgba(0,0,0,.1);border-bottom-style:solid;border-bottom-width:1px;padding:var(--space)}}.cover-section__image[data-v-0d100566]{grid-area:image;text-align:center;padding-left:var(--space);}.cover-section__image img[data-v-0d100566]{width:100%;height:auto;max-width:60%;margin:auto;}@media (min-width: 900px){.cover-section__image img[data-v-0d100566]{max-width:100%}}.cover-section__list[data-v-0d100566]{list-style-type:none}.cover-section__list-item p[data-v-0d100566]{line-height:1.5rem;margin:.5rem 0}.cover-section__list-item p[data-v-0d100566]:before{content:\"\";display:inline-block;height:16px;width:25px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;}@media (min-width: 900px){.cover-section__list-item p[data-v-0d100566]:before{margin-left:-40px;margin-right:.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
