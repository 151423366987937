














import Vue from 'vue'
import { MetaInfo } from 'vue-meta/types'

export default Vue.extend({
  name: 'HomePage',

  layout: 'HomeLayout',

  head(): MetaInfo {
    return {
      title: 'Cambiar Compañia Luz',
      meta: [
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: 'Cambiar Compañia de Luz',
        },
        {
          property: 'og:description',
          content:
            'Entra e infórmate sobre todo tipo de dudas y trámites relacionados con tu suministro de luz o placas solares en CambiarCompaniaLuz.com',
        },
        {
          property: 'og:url',
          content: this.$config.baseUrl,
        },
        {
          property: 'og:image',
          content: `${this.$config.baseUrl}/og-cover.png`,
        },
      ],
    }
  },
  mounted() {
    this.$pushGtm4Event({
      business_unit: 'electricity',
    })
  },
})
