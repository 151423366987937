import { render, staticRenderFns } from "./HighlightItem.vue?vue&type=template&id=6703749a&scoped=true&"
import script from "./HighlightItem.vue?vue&type=script&lang=ts&"
export * from "./HighlightItem.vue?vue&type=script&lang=ts&"
import style0 from "./HighlightItem.vue?vue&type=style&index=0&id=6703749a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6703749a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseText: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseText.vue').default,BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default})
